/*  eslint-disable */
import React from "react"
import { Helmet } from "react-helmet"

import Header from "src/componentsV2/sections/Header"
import { Footer } from "src/sections/landing"
import WidthWrapper from "src/components/widthWrapper"

const PrivacyDoc = () => (
  <div className="leading-loose pb-24 pt-90/16 md:pt-40">
    <Helmet>
      <meta name="robots" content="all" />
      <title>AirMason | Privacy Notice</title>
      <meta name="description" content="" />
    </Helmet>
    <h1>Privacy Notice</h1>
    <p>Last Updated: June 5, 2023</p>
    <p>
      This Privacy Notice explains the practices that AirMason Inc (“airmason”,
      “we”, “us”) follows in connection with the personal data that we collect
      through the use of our applications, visits to our website, or when you
      contact us directly.
    </p>
    <br />
    <h3>What is Personal Data?</h3>
    <p>
      For the purposes of the work that airmason performs and the regulations
      that airmason needs to comply with, personal data refers to any
      information that relates to an identified or identifiable individual such
      as a name, email, mailing address or phone number.
    </p>
    <br />
    <h3>Privacy regulations we need to comply with</h3>
    <p>
      Because AirMason is a technology organization that provides products and
      services worldwide, we are expected to comply with different Privacy and
      Data Protection Regulations such as the General Data Protection Regulation
      (“GDPR”) in the EU, the California Consumer Privacy Act (“CCPA”) for
      residents in California and the Personal Information Protection and
      Electronic Documents Act (“PIPEDA”) in Canada.
    </p>
    <p>
      At AirMason we respect your privacy rights, and we commit to protecting
      your personal data according to the expectations of the laws of where you
      are located.
    </p>
    <br />
    <h3>Our responsibility to your personal data</h3>
    <p>
      Some Privacy Regulators classify organizations depending on the role they
      play while processing personal data.
    </p>
    <p>
      A Data Controller is an organization that determines how to collect and
      process personal data.
    </p>
    <p>
      A Data Processor is an organization that processes personal data on behalf
      of a Data Controller.
    </p>
    <p>
      AirMason is considered a Data Controller when collecting and processing
      personal data from visitors to our marketing website or from prospective
      and active customers who are interested in using or use our application.
    </p>
    <p>
      Our Customers collect and manage personal data when using AirMason’s
      applications. We are considered a Data Processor when we process such data
      under the direction of our customers.
    </p>
    <p>
      Both as a Data Controller or as a Data Processor, AirMason protects
      personal data by following a strict privacy framework.
    </p>
    <br />
    <br />
    <h2>HOW WE COLLECT AND PROCESS YOUR PERSONAL DATA</h2>
    <h4>
      Personal data we collect and process when you use our marketing website
    </h4>
    <br />
    <h3>Booking a demo and interacting with Live Support</h3>
    <p>
      We collect personal data provided directly by you when you register to
      watch an AirMason demo or interact with support via the website. We
      collect your name and email address.
    </p>
    <p>
      We use your personal data to carry out your requests and provide the demo
      or webinar you are interested in.
    </p>
    <br />
    <h3>Requests for contact</h3>
    <p>
      We collect personal data provided directly by you during direct
      communication with any of our representatives either through email or
      through forms on our website. We collect your name, email address, phone
      number and the reason for your communication.
    </p>
    <p>We use your personal data to acknowledge and respond to your request.</p>
    <br />
    <h3>Signing up for email communication</h3>
    <p>
      We collect your email address if you agree to sign up for email
      communication with AirMason.
    </p>
    <p>
      We send emails to the email address you have provided to keep you updated
      with information about AirMason and the services we provide which we think
      you may be interested in.
    </p>
    <br />
    <h3>Navigating our website</h3>
    <p>
      We collect information about your visit such as your IP address, what
      pages you visited and what sections of our website were of most interest
      to you.
    </p>
    <p>
      We use cookies and Google Analytics to gain insights into how our visitors
      navigate the website in order to provide you with a better web experience.
    </p>
    <p>
      We also use third-party cookies for you to receive relevant ads that are
      of interest to you.
    </p>
    <br />
    <h3>Personal data we collect and use for our application</h3>
    <p>
      We collect different pieces of personal data directly from you when you
      sign up for and use our application and from employees to our customer’s
      handbooks, that include:
    </p>
    <ul>
      <li>
        <p>Contact information including your full name and email address.</p>
      </li>
      <li>
        <p>Account information including your username and password.</p>
      </li>
      <li>
        <p>
          Billing and other payment information, subscriptions, account
          preferences, feedback and survey responses.
        </p>
      </li>
      <li>
        <p>Handbook content, pictures and videos.</p>
      </li>
      <li>
        <p>Information about how you access and use our application.</p>
      </li>
      <li>
        <p>
          AirMason Handbook Login & Signature analytics including name, email
          address, location, IP address.
        </p>
      </li>
      <li>
        <p>
          AirMason Handbook Welcome Email interactions including views and
          clicks
        </p>
      </li>
    </ul>
    <br />
    <p>Our application requires this data about you to be able to:</p>
    <ul>
      <li>
        <p>Register and login to the AirMason application.</p>
      </li>
      <li>
        <p>Manage customer account.</p>
      </li>
      <li>
        <p>Fulfill and manage your subscription and payments.</p>
      </li>
      <li>
        <p>Respond to customer inquiries/offer support to customers.</p>
      </li>
      <li>
        <p>Administration of our business and to improve our Services.</p>
      </li>
    </ul>
    <br />
    <h3>Our legal basis for processing your personal data</h3>
    <p>
      We only collect, process, use, share and store your personal data where we
      have a legal basis to do so. We will only process your personal data if:
    </p>
    <ul>
      <li>
        <p>
          We have obtained your explicit consent prior to the processing of your
          personal data.
        </p>
      </li>
      <li>
        <p>
          If we need your personal data to perform a contractual obligation.
        </p>
      </li>
      <li>
        <p>
          If we need to process your personal data to fulfill our legal and
          regulatory obligations.
        </p>
      </li>
      <li>
        <p>
          If we have a legitimate interest that will not put your rights and
          freedoms at risk.
        </p>
      </li>
    </ul>
    <br />
    <h3>Who has access to your personal data?</h3>
    <p>
      AirMason will only disclose your personal data to the following parties
      under specific circumstances:
    </p>
    <ul>
      <li>
        <p>AirMason personnel, if required, to fulfill your request.</p>
      </li>
      <li>
        <p>Service providers that support our systems and applications.</p>
      </li>
      <li>
        <p>
          Law enforcement, regulatory bodies or courts, when we are required to
          do so under applicable laws and regulations.
        </p>
      </li>
      <li>
        <p>
          In connection with the sale or reorganization of all or part of our
          business, as permitted by applicable law.
        </p>
      </li>
    </ul>
    <br />
    <h3>How do we keep your personal data safe?</h3>
    <p>
      AirMason is committed to protecting the personal data we collect, process
      and disclose about you. We maintain appropriate safeguards and take
      reasonable steps to protect your personal data, ensure that we limit its
      use and that we disclose it only to the parties that have a legitimate
      reason to have access to it.
    </p>
    <p>
      We ensure that all the parties that we disclose your personal data to,
      internally and externally, have contractual obligations to protect the
      security and the confidentiality of your personal data.
    </p>
    <br />
    <h3>Where is your personal data stored?</h3>
    <p>
      The personal data that we collect from you for the purpose of our
      applications resides in either the United States or EU depending on the
      home country of our customers. We ensure that your data is safeguarded and
      work with our partners to ensure that the risks to your privacy are
      minimized.
    </p>
    <p>
      For personal data transferred out of the EU into countries that are not
      considered to have an adequate level of personal data protection, we have
      implemented Standard Contractual Clauses to ensure that we have a valid
      data transfer mechanism in place that ensures the protection of your
      personal data once it is transferred.
    </p>
    <br />
    <h3>How long do we retain your personal data?</h3>
    <p>
      We are expected to retain your personal data for an established period of
      time for regulatory, legal, tax or business requirements.
    </p>
    <p>
      We retain your personal data for no longer than reasonably necessary to
      fulfill the purposes for which we collected it or to comply with the law,
      prevent fraud, facilitate an investigation, defend against legal claims,
      or exercise our legal rights.
    </p>
    <br />
    <h3>Personal data from minors</h3>
    <p>
      We do not knowingly collect personal information from anyone under the age
      of 18. If you are a parent or guardian and you are aware that your child
      has provided us with Personal Data, please contact us at&nbsp;
      <a href="mailto:privacy@airmason.com">privacy@airmason.com</a>.
    </p>
    <p>
      If we become aware that we have collected Personal Data from children
      without verification of parental consent, we take steps to remove that
      information from our servers.
    </p>
    <br />
    <h3>What rights do you have regarding your personal data?</h3>
    <p>
      Subject to any exceptions provided by law, you have the right to request
      access to, update or deletion of your personal data.
    </p>
    <p>
      You also have the right to request restriction of or object to the
      processing of your personal data and you have the right to request to have
      your data transferred to another organization in a commonly used format.
    </p>
    <p>
      On each particular case we will inform you of the consequences of your
      request and if there are any exemptions to honouring your requests based
      on legal or contractual requirements.
    </p>
    <p>
      To submit any request about your personal data you may contact us via
      email at&nbsp;
      <a href="mailto:privacy@airmason.com">privacy@airmason.com</a>.
    </p>
    <br />
    <br />
    <h3>Personal data from residents from California</h3>
    <h4>
      Supplemental to the above, the following additional information pertains
      to California residents:
    </h4>
    <br />
    <h3>Categories of Personal Data collected and disclosed</h3>
    <p>
      Categories of personal data collected about California residents in the
      past twelve months include identifiers, characteristics of protected
      class, commercial information, customer records information, Internet or
      other electronic network activity information, and inferences drawn from
      such information. For examples of specific pieces of personal information
      collected, please see the “How we collect and process your personal data”
      section above.
    </p>
    <p>
      AirMason has shared personal data in the last twelve months on all the
      categories identified above for the purposes described in the “Personal
      Data Disclosure” section of this Privacy Notice.
    </p>
    <br />
    <h3>Your California Privacy Rights</h3>
    <p>
      As a California resident, you have the following rights with respect to
      your personal information:
    </p>
    <ul>
      <li>
        <p>
          You have the right to request to know about personal information
          collected, disclosed, or sold.
        </p>
      </li>
      <li>
        <p>
          You have the right to request the deletion of personal information.
        </p>
      </li>
      <li>
        <p>
          You have the right to opt-out of the sale of your personal information
          at any time.
        </p>
      </li>
    </ul>
    <br />
    <p>
      Finally, you have the right to not be discriminated against as a result of
      exercising your privacy rights. Accordingly, unless permitted by law, we
      will not deny you goods or services, charge you different prices or rates
      for goods or services, provide you a different level or quality of goods
      or services, or suggest you will receive a different price or rate for
      goods or services or a different level or quality of goods or services.
    </p>
    <br />
    <h3>How to contact us</h3>
    <p>
      If you have any further questions regarding the personal data that
      AirMason or any of our partners collect and process or if you have
      feedback regarding this Privacy Notice, you may contact us at&nbsp;
      <a href="mailto:privacy@airmason.com">privacy@airmason.com</a>.
    </p>
    <br />
    <h3>
      EU individuals – Right to Lodge a Complaint with a Supervisory Authority
    </h3>
    <p>
      If you reside in the EU and want to lodge a complaint with a Supervisory
      Authority (Data Protection Authority) you may do so in the Member State
      where you reside, where you work or where you may have experienced an
      issue with the processing of your personal data.
    </p>
    <br />
    <h3>How we update this Privacy Notice</h3>
    <p>
      We reserve the right to modify this Privacy Notice at any time in
      accordance with applicable law. If we do so, please look at the “Last
      Updated” legend to see when this Privacy Notice was last revised. Any
      changes to this Privacy Notice will become effective when we post the
      revised Privacy Notice on the Website.
    </p>
  </div>
)

const Privacy = ({}) => {
  return (
    <React.Fragment>
      <Header />
      <WidthWrapper>
        <PrivacyDoc />
      </WidthWrapper>
      <Footer />
    </React.Fragment>
  )
}

export default Privacy
